import * as React from 'react';
import '../styles/services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import { Service } from '../components/service-card';

import quotitien from '../assets/images/quotidien.jpg';
import bureaux from '../assets/images/bureaux.jpg';
import camping from '../assets/images/camping.jpg';
import hotels from '../assets/images/hotel.jpg';
import copro from '../assets/images/copro.jpg';
import chantier from '../assets/images/chantier.jpg';
import hopital from '../assets/images/hopital.jpg';
import bateau from '../assets/images/bateau.jpg';

export const Services = () => {
  const data = [
    {
      title: 'Nettoyage des bureaux',
      text: "Nos services de nettoyage de bureau permettent à votre lieu de rester frais toute l'année, ce qui permet à votre équipe de se concentrer sur l'essentiel.",
      img: bureaux,
    },
    {
      title: 'Nettoyage quotidien',
      text: 'Nous adaptons notre service en fonction de vos besoins personnels de nettoyage afin que vous puissiez poursuivre vos activités quotidiennes.',
      img: quotitien,
    },
    {
      title: 'Nettoyage des campings',
      text: 'Nous assurons un nettoyage professionnel avant et pendant la saison en respectant tout type de besoins.',
      img: camping,
    },
    {
      title: 'Entretien des hôtels',
      text: 'Nous assurons au quotidien la propreté au sein des différentes parties des hôtels: les chambres, les parties communes, la réception.',
      img: hotels,
    },
    {
      title: 'Nettoyage des copropriétés',
      text: 'Nous assurons le nettoyage des parties communes qui sont des zones de fort passage pour les garder propres et agréables pour les résidents.',
      img: copro,
    },
    {
      title: 'Nettoyage des bâteaux',
      text: 'Nos services de nettoyage sont mis à votre disposition pour vous permettre de profiter de votre bateau dans les meilleures conditions.',
      img: bateau,
    },
    {
      title: 'Nettoyage de chantier',
      text: 'Nous fournissons un nettoyage professionnel pendant et en fin de chantier d’entreprise du BTP pour assurer l’épanouissement de vos équipes.',
      img: chantier,
    },
    {
      title: 'Nettoyage du secteur médical',
      text: 'Nos services de nettoyage sont parfaitement adaptés à vos différents espaces nécessitant le plus d’attention et de désinfection afin de limiter la propagation des microbes et virus.',
      img: hopital,
    },
  ];

  return (
    <>
      <section className="container-serv" id="services">
        <h3 className="title">
          <FontAwesomeIcon className="icon" icon={faBroom} />
          Services
        </h3>
        <div className="cards-list">
          {data.map((service, i) => (
            <Service
              key={i}
              title={service.title}
              text={service.text}
              img={service.img}
              alt={service.title}
            />
          ))}
        </div>
      </section>
    </>
  );
};
