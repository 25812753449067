import * as React from 'react';
import '../styles/contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ContactForm } from '../components/contact-form';
import { DevisForm } from '../components/devis-form';

export const Contact = () => {
  const [isDevis, setIsDevis] = React.useState(false);

  const NousContacter = () => {
    if (isDevis) {
      setIsDevis(!isDevis);
    }
  };

  const Devis = () => {
    if (!isDevis) {
      setIsDevis(!isDevis);
    }
  };

  return (
    <>
      <section className="container-contact" id="contact">
        <h3 className="title">
          <FontAwesomeIcon className="icon" icon={faEnvelope} />
          Nous contacter
        </h3>
        <div className="contact-form">
          <div className="contact-btn">
            <button
              className={!isDevis ? 'selected' : ''}
              onClick={NousContacter}
            >
              Nous contacter
            </button>
            <button className={isDevis ? 'selected' : ''} onClick={Devis}>
              Demander un devis
            </button>
          </div>

          {!isDevis ? <ContactForm /> : <DevisForm />}
        </div>
      </section>
    </>
  );
};
