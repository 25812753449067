import * as React from 'react';
import '../styles/contact-form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import { Notyf } from 'notyf';

export const ContactForm = () => {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');

  const notyf = new Notyf({
    duration: 3000,
    position: {
      x: 'right',
      y: 'top',
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !name || !phone || !message) {
      return notyf.error('Veuillez remplir tous les champs !');
    }

    const templateId = 'template_yrtvaqa';
    const serviceId = 'service_487p5y7';
    const userId = 'user_Zhd20Q7hu3JE80SYHLeTP';

    const variables = {
      email: email,
      from_name: name,
      phone: phone,
      message: message,
    };

    emailjs
      .send(serviceId, templateId, variables, userId)
      .then((res) => {
        setEmail('');
        setName('');
        setPhone('');
        setMessage('');
        notyf.success('Email de contact envoyé !');
      })
      .catch((err) => {
        notyf.error('Une erreur est survenue !');
      });
  };

  return (
    <div className="form-container">
      <div className="form">
        <div className="form-input">
          <p>Adresse mail*</p>
          <input
            type="email"
            name="mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>NOM Prénom*</p>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>Numéro de téléphone*</p>
          <input
            type="phone"
            name="phone"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>Message*</p>
          <textarea
            className="textarea"
            type="text"
            name="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </div>
        <div className="btn-container">
          <button className="send-form">
            <FontAwesomeIcon
              className="icon"
              icon={faPaperPlane}
              onClick={handleSubmit}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
