import * as React from 'react';
import { Contact } from './pages/contact';
import { Footer } from './pages/footer';
import { Home } from './pages/home';
import { Presentation } from './pages/presentation';
import { Services } from './pages/services';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const App = () => {
  return (
    <>
      <Home />
      <Presentation />
      <Services />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
