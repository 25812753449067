import * as React from 'react';
import '../styles/contact-form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import { Notyf } from 'notyf';

export const DevisForm = () => {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [city, setCity] = React.useState('');
  const [surface, setSurface] = React.useState(0);
  const [type, setType] = React.useState('');

  const notyf = new Notyf({
    duration: 3000,
    position: {
      x: 'right',
      y: 'top',
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(email, name, phone, message, city, surface, type);

    if (!email || !name || !phone || !message || !city || !type) {
      return notyf.error('Veuillez remplir tous les champs !');
    }

    const templateId = 'template_zbtoct4';
    const serviceId = 'service_487p5y7';
    const userId = 'user_Zhd20Q7hu3JE80SYHLeTP';

    const variables = {
      email: email,
      from_name: name,
      phone: phone,
      message: message,
      type: type,
      ville: city,
      surface: surface,
    };

    emailjs
      .send(serviceId, templateId, variables, userId)
      .then((res) => {
        setEmail('');
        setName('');
        setPhone('');
        setMessage('');
        setCity('');
        setSurface(0);
        setType('');
        notyf.success('Email de contact envoyé !');
      })
      .catch((err) => {
        notyf.error('Une erreur est survenue !');
      });
  };

  return (
    <div className="form-container">
      <div className="form">
        <div className="form-input">
          <p>Adresse mail*</p>
          <input
            type="email"
            name="mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>NOM Prénom*</p>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>Numéro de téléphone*</p>
          <input
            type="phone"
            name="phone"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>Type de nettoyage*</p>
          <select name="type" onChange={(event) => setType(event.target.value)}>
            <option value=""></option>
            <option value="Nettoyage des bureaux">Nettoyage des bureaux</option>
            <option value="Nettoyage quotidien">Nettoyage quotidien</option>
            <option value="Entretien des hôtels">Entretien des hôtels</option>
            <option value="Nettoyage des campings">
              Nettoyage des campings
            </option>
            <option value="Nettoyage des copropriétés">
              Nettoyage des copropriétés
            </option>
            <option value="Nettoyage des bâteaux">Nettoyage des bâteaux</option>
            <option value="Nettoyage de chantier">Nettoyage de chantier</option>
            <option value="Nettoyage du secteur médical">
              Nettoyage du secteur médical
            </option>
          </select>
        </div>
        <div className="form-input">
          <p>Ville*</p>
          <input
            type="text"
            name="city"
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>Surface*</p>
          <input
            type="number"
            name="surface"
            value={surface}
            onChange={(event) => setSurface(event.target.value)}
          />
        </div>
        <div className="form-input">
          <p>Message</p>
          <textarea
            className="textarea"
            type="text"
            name="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </div>
        <div className="btn-container">
          <button className="send-form">
            <FontAwesomeIcon
              className="icon"
              icon={faPaperPlane}
              onClick={handleSubmit}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
