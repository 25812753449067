import * as React from 'react';
import '../styles/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faEnvelope,
  faBroom,
  faBuilding,
  faPhone,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const Home = () => {
  return (
    <>
      <div className="header">
        <a className="header-text" href="tel:0652599399">
          <FontAwesomeIcon className="icon" icon={faPhoneAlt} />
          Contactez nous: <span>+33 6 52 59 93 99</span>
        </a>
        <a className="header-text" href="mailto:acinettoyage@outlook.fr">
          <FontAwesomeIcon className="icon" icon={faEnvelope} />
          acinettoyage@outlook.fr
        </a>
      </div>
      <div className="navbar" id="home">
        <ul>
          <li className="logo">
            <p>ACI Nettoyage</p>
          </li>

          <div className="tab">
            <li>
              <a href="#home" className="tab-text">
                <FontAwesomeIcon className="icon" icon={faHome} />
                Accueil
              </a>
            </li>
            <li>
              <a href="#presentation" className="tab-text">
                <FontAwesomeIcon className="icon" icon={faBuilding} />
                Présentation
              </a>
            </li>
            <li>
              <a href="#services" className="tab-text">
                <FontAwesomeIcon className="icon" icon={faBroom} />
                Services
              </a>
            </li>
            <li>
              <a href="#contact" className="tab-text">
                <FontAwesomeIcon className="icon" icon={faEnvelope} />
                Nous contacter
              </a>
            </li>
            <li>
              <div className="social-media">
                <a
                  className="tab-text"
                  href="https://www.instagram.com/acinettoyage"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <FontAwesomeIcon className="icon" icon={faInstagram} />
                </a>
                <a
                  className="tab-text"
                  href="https://www.facebook.com/ACI-Nettoyage-432495523968976/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <FontAwesomeIcon className="icon" icon={faFacebookF} />
                </a>
                <a className="tab-text" href="tel:0644849479" rel="noreferrer">
                  <FontAwesomeIcon className="icon" icon={faPhone} />
                </a>
              </div>
            </li>
          </div>
        </ul>
      </div>

      <div className="hero-banner">
        {/* 
          Mettre l'image de background ici
          Placer au centrer le nom de l'entreprise, une petite phrase de présentation et un bouton
        */}
        <div className="hero-background"></div>
        <div className="hero-text">
          <h3 className="hero-title">ACI Nettoyage</h3>
          <hr className="hero-separator" />
          <p className="hero-subtitle">
            ACI Nettoyage s'engage à créer un environnement propre et sain pour
            vous. En plus de nos services professionnels de nettoyage, nous
            offrons des services de désinfection pour votre maison afin
            d'assurer le bien-être de votre famille.
          </p>
          <div className="button-container">
            <a href="#contact">
              <button className="hero-button">Demander un devis</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
