import * as React from 'react';
import '../styles/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-footer">
          <div className="address">
            <p>409 Chemin du Fort Rouge</p>
            <p>83200 Toulon</p>
          </div>
          <div className="divider"></div>
          <div className="contact">
            <a href="tel:0652599399">
              <span>+33 6 52 59 93 99</span>
            </a>
            <a href="mailto:acinettoyage@outlook.fr">acinettoyage@outlook.fr</a>
          </div>
        </div>

        <div className="social">
          <a
            className="tab-text"
            href="https://www.instagram.com/acinettoyage"
            target={'_blank'}
            rel="noreferrer"
          >
            <FontAwesomeIcon className="icon" icon={faInstagram} />
          </a>
          <a
            className="tab-text"
            href="https://www.facebook.com/ACI-Nettoyage-432495523968976/"
            target={'_blank'}
            rel="noreferrer"
          >
            <FontAwesomeIcon className="icon" icon={faFacebookF} />
          </a>
        </div>
        <p className="legal">ACI Nettoyage © 2022</p>
      </footer>
    </>
  );
};
