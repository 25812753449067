import * as React from 'react';
import '../styles/presentation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { MapContainer } from '../components/map';

export const Presentation = () => {
  return (
    <>
      <section className="container-pres" id="presentation">
        <h3 className="title">
          <FontAwesomeIcon className="icon" icon={faBuilding} />
          Présentation de l'entreprise
        </h3>
        <div className="flex-pres">
          <div className="desc">
            <p>
              ACI Nettoyage est une entreprise fondée en 2019, située à Toulon.
              Notre entreprise est spécialisée dans le nettoyage courant et
              l'entretien de tout type de locaux auprès des particuliers, des
              entreprises, des syndics d'immeuble et des collectivités.
            </p>
            <p>
              Grâce au travail acharné et au dévouement constant au cours des
              premières années d'activité, notre nom n'a pas cessé de croître
              pour atteindre un niveau professionnel et un niveau d'excellence.
            </p>
            <p>
              Tous ces efforts ont permis à notre entreprise de se développer
              au-delà de nos attentes initiales et, depuis sa création, nous
              avons eu le plaisir de servir plusieurs clients.
            </p>
          </div>
          <div className="map">
            <MapContainer />
          </div>
        </div>
      </section>
    </>
  );
};
