import * as React from 'react';
import '../styles/service-card.css';

export const Service = (props) => {
  return (
    <>
      <div className="card">
        <img className="card-img" src={props.img} alt={props.alt} />
        <h4 className="card-title">{props.title}</h4>
        <p className="card-text">{props.text}</p>
      </div>
    </>
  );
};
