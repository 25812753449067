import React from 'react';
import { MapContainer as Map, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import svgPin from '../assets/images/pin.svg';

export const icon = new Icon({
  iconUrl: svgPin,
  iconSize: [35, 35],
});

export const MapContainer = () => {
  return (
    <>
      <div className="map">
        <Map
          center={[43.14227226046136, 5.916597783178286]}
          zoom={12}
          scrollWheelZoom={true}
          style={{ borderRadius: '50%' }}
        >
          <TileLayer
            attribution='<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?lang=fr&access-token={accessToken}"
            minZoom={0}
            maxZoom={22}
            accessToken="LqE4pmZiDDZ61bePtFiTnKIFO27lxOXT9zKhonnnxZznuGKGXt9n4HG8eY3ToNP1"
          />
          <Marker
            position={[43.14227226046136, 5.916597783178286]}
            icon={icon}
          ></Marker>
        </Map>
      </div>
    </>
  );
};
